.normal{
    font-weight: normal;
}
.fs-16{
    font-size: 16px;
}
.fs-14{
    font-size: 14px;
}
.fs-12{
    font-size: 12px;
}