.bg {
  background-image: url(./../assets/mainBg.png);
  background-size: contain;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  // background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 40px;
  overflow-x: hidden;
  .menuIcon{
    width:50px;
    @media only screen and (max-width: 600px) {
      width:30px;
  
     }
  }
  .bg-about {
    &:before {
      background-image: url(./../assets/earth.png);
      content: "";
      background-size: contain;
      background-position: center;
      width: 64vh;
      min-height: 100vh;
      background-repeat: no-repeat;
      position: absolute;
      right: 0px;
      top: 0px;
      @media only screen and (max-width: 600px) {
        width: 26vh;
        bottom: -54%;
    
       }
    }
    
  }
  .mobile_main{
    display: none !important;
  }
  .d-xl-none{
    @media only screen and (max-width: 600px) {
      display: none;
     }
  }
  .logo {
    width: 100%;
    height: 200px;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  .text-lgc {
    background: -webkit-linear-gradient(#b3b3b3 49%, #ffffff 26.08%, #b3b3b3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .lgcenterbtn {
    position: absolute;
    top: 60%;
    align-items: center;
    margin: auto;
    padding: 30;
    height: 50px;
    background-color: transparent;
    width: 200px;
    border-radius: 10px;
    border: 1px solid #fff;
  }
  .register-logo{
    @media only screen and (max-width: 600px) {
      width: 50%;
      transform: rotate(-4.84deg);
    }
  }
  .form-container {
    border: 1px solid white;
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 10px;
    width: 500px;
    padding: 20px;
    border-radius: 10px;
    padding-bottom: 60px;
    padding-top: 10px;
    @media only screen and (max-width: 600px) {
      width: 350px;
    }
    background-origin: border-box;
    background-clip: content-box, border-box;
    @media only screen and (max-width: 600px) {
      border: none !important;
      }
    .submit-btn {
      background: -webkit-linear-gradient(
        #b3b3b3 0%,
        #ffffff 52.08%,
        #b3b3b3 100%
      );
      width: 250px;
      height: 45px;
      border-radius: 10px;
      border: none;
      margin: auto;
      text-align: center;
      display: block;
      margin-top: 30px;
    }
    .input-box {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      width: 380px;
      @media only screen and (max-width: 600px) {
        width: 300px;
      }
      align-self: center;
      justify-content: center;
      margin: auto;
      margin-top: 20px;
      .input {
        border-width: 0;
        border-bottom: 1px solid #fff;
        background-color: transparent;
        width: 100%;
        color: white;
        outline: none;
      }
    }
  }
  .toggle-btn {
    background-color: rgba(0, 0, 0, 0);
    border-width: 0px;
    position: absolute;
    top: 30px;
    left: 30px;
    width:100;
    @media only screen and (max-width: 600px) {
      top: 15px;
      left: 15px;
    }
  }
  .toggle-btn-c {
    position: absolute;
    flex-direction: column;
    display: flex;
    top: 100px;
    left: 35px;
    @media only screen and (max-width: 600px) {
      top: 65px;
    left: 25px;
    }
    &.exceptional{
      @media only screen and (max-width: 600px) {
        position: relative;
      }
    }
    z-index: 1000000;
    .tgbtn {
      background-color: rgba(0, 0, 0, 0);
      border: none;
      color: white;
      font-size: 18px;
      margin-top: 7px;
      text-align: left;
    }
  }
  
  .rect-container {
    // background-image: url(./../assets/rectangle.png);
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
    
    p{
      font-size:21px;
    }
    @media only screen and (max-width: 600px) {
      background-image:none;
      padding-top: 10% !important;
      width: 100% !important;
      min-height: 30vh;
      p{
        font-size: 16px;
      }
    }
  }
  .about-head {
    font-family: "PlayfairDisplay-Bold";
    src: url(./../assets/fonts/PlayfairDisplay-Bold.ttf) format("truetype");
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 53px;
    letter-spacing: 0.1em;
    // width:300px;

    color: #ffffff;
    @media only screen and (max-width: 600px) {
      font-size: 16px;
      line-height: 10px;
    }
  }
  .about-desc {
    font-family: "PlayfairDisplay-Black";
    src: url(./../assets/fonts/PlayfairDisplay-Black.ttf) format("truetype");
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 154.3%;
    color: white;
    @media only screen and (max-width: 600px) {
      font-size: 14px;
      width: 90%;
     }
     &.ex25{
      font-size: 23px!important;
      letter-spacing: -0.6px;
      @media only screen and (max-width: 1400px) {
        font-size: 20px !important;
       }
       @media only screen and (max-width: 600px) {
        font-size: 12px !important;
        width: 100%;
       }
     }
     &.ex15{
       @media only screen and (max-width: 600px) {
        font-size: 15px !important;
       }
     }
  }
  .logo-rect {
    // height: 500px;
    position: absolute;
    top:30%;
    @media only screen and (max-width: 1400px) {
      left: -10%;
     }
     @media only screen and (max-width: 1050px) {
      left: -35%;
      top:33%;
     }
    // width: 100%;
    &:after {
      content: url(../assets/logo-rect3.png);
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      top: -94%;
      left: -44%;
      // @media only screen and (max-width: 1000px) {
      //   font-size: 14px !important;
      //   left: -100%;
      //  }
       
    }
    //Small Screen Tweeks
    @media only screen and (max-width: 1000px) {
      
      display: none;
    }
  }
  .align-right-sec-con {
    padding-top: 10%;
    padding-left: 10%;
    @media only screen and (max-width: 1400px) {
      padding-top: 20%;
     }
    @media only screen and (max-width: 600px) {
      padding-top: 0%;
      margin-top: -15px;
      padding-left: 3%;
    }
  }
  .ld-portion{
    padding-left: 3rem !important;
    @media only screen and (max-width: 600px) {
      padding-left: 42px !important;
    }
  }
  .align-right-sec {
    margin-top: 15%;
    @media only screen and (max-width: 600px) {
      margin-top: 8%;
    }
  }
  .about-right-head {
    font-family: "PlayfairDisplay-Bold";
    src: url(./../assets/fonts/PlayfairDisplay-Bold.ttf) format("truetype");
    color: white;
    font-weight: bold;
    font-size: 19px;
    margin-bottom: 0px;
    @media only screen and (max-width: 600px) {
      font-size: 12px;
    }
  }
  .openSans{
    font-family: "Open Sans";
    src:url(./../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf) format("truetype");
  }
  .about-bottom{
    margin-top:5%;
    @media only screen and (max-width: 600px) {
      margin-top:-11%;
    }
  }
  
  .about-right-para {
    font-style: normal;
    font-family: "PlayfairDisplay-Black";
    src: url(./../assets/fonts/PlayfairDisplay-Black.ttf) format("truetype");
    font-size: 19px;
    color: white;
    line-height: 154.3%;
    max-width: 65%;
    @media only screen and (max-width: 1400px) {
      max-width: 80%;
    }
    @media only screen and (max-width: 600px) {
      max-width: 57%;
      font-size: 12px;
    }
  }
  .content-box {
    height: 80vh;
    padding-right: 20px;
    overflow-y: scroll;
    border-width: 0.1px;
    border-style: solid;
    // linear-gradient(357.63deg, #000000 2.02%, rgba(0, 0, 0, 0) 98.01%)
    border-image: linear-gradient(to bottom, #fff 2.02%, #000 98.01%) 1 100%;;
    // &:before {
    //   background-image: url(./../assets/smline.png);
    //   content: "";
    //   background-size: contain;
    //   background-position: center;
    //   width: 64px;
    //   min-height: 100px;
    //   background-repeat: no-repeat;
    //   position: absolute;
    //   right: 0px;
    //   top: 40%;
    // }
    @media only screen and (max-width: 600px) {
      border: 1px solid white;
      margin: 10px;
      padding-top: 32px;
      margin-top: -8px;
    }
    &::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;
      
     }
   
     &::-webkit-scrollbar-thumb {
      // background-color: rgba(255,255,255,.1);
      // border-radius: 3px;
      background-image: url(./../assets/smline.png);
      content: "";
      background-size: contain;
      background-position: center;
      width: 64px;
      min-height: 100px;
      background-repeat: no-repeat;
      &:hover {
        background-image: url(./../assets/smline.png);
        content: "";
        background-size: contain;
        background-position: center;
        width: 64px;
        min-height: 100px;
        background-repeat: no-repeat;
        position: absolute;
        right: 0px;
        top: 40%;
      }
     }
  }


  .exbold{
    @media only screen and (max-width: 600px) {
    font-weight: bold;
      }
  }
  .excls{
    @media only screen and (max-width: 600px) {
    
    margin-bottom: 10px;
    }
   
  }
  
}



.bg_landing {
    position: relative;
  
    background-image: url(./../assets/mainBg.png);
    background-size: contain;
    background-position: center;
    width: 100%;
    min-height: 100vh;
    // background-color: #000;
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
  
    .logo {
      width: 100%;
      height: 200px;
      flex-direction: column;
      display: flex;
      align-items: center;
    }
    .text-lgc {
      background: -webkit-linear-gradient(#b3b3b3 49%, #ffffff 26.08%, #b3b3b3 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .lgcenterbtn {
      position: absolute;
      top: 60%;
      align-items: center;
      margin: auto;
      padding: 30;
      height: 50px;
      background-color: transparent;
      width: 200px;
      border-radius: 10px;
      border: 1px solid #fff;
    }
    .form-container {
      border: 1px solid white;
      background-origin: border-box;
      background-clip: content-box, border-box;
      border-radius: 10px;
      width: 500px;
      padding: 20px;
      border-radius: 10px;
      padding-bottom: 60px;
      padding-top: 10px;
      background-origin: border-box;
      background-clip: content-box, border-box;
      @media only screen and (max-width: 600px) {
      border: none !important;
      }
      .submit-btn {
        // background: -webkit-linear-gradient(
        //   #b3b3b3 0%,
        //   #ffffff 52.08%,
        //   #b3b3b3 100%
        // );
        background-color:none !important;
        border:1px solid #fff;
        width: 250px;
        height: 45px;
        border-radius: 10px;
        border: none;
        margin: auto;
        text-align: center;
        display: block;
        margin-top: 30px;
      }
      .input-box {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: 380px;
        align-self: center;
        justify-content: center;
        margin: auto;
        margin-top: 20px;
        .input {
          border-width: 0;
          border-bottom: 1px solid #fff;
          background-color: transparent;
          width: 100%;
          color: white;
          outline: none;
        }
      }
    }
    .man1_img_div_responsive,
    .man2_img_div_responsive {
      display: none;
    }
  
    .man2_img_div_animate {
      width: 53%;
      position: absolute;
      right: 0;
      top: 0;
    }
    .man1_img_div_animate {
      width: 68%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      position: absolute;
      bottom: 0;
    }
    .logo_div {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top:30px
    }
    .man1_img_animate {
      overflow: hidden !important;
      width: 100%;
    }
    .man2_img_animate {
      overflow: hidden !important;
      width: 100%;
    }
    .wow,
    .animate__fadeInBottomLeft,
    .animate__fadeInTopRight {
      overflow: hidden !important;
    }
  
    :root {
      overflow: hidden !important;
    }
    .blur_div {
      width: 100%;
    }
    .blur_img_div {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      height: 100vh;
      position: absolute;
      width: 100%;
      z-index: 1000;
    }
    .blur_img {
      width: 35rem;
      margin-left: -14px;
      margin-top: 25px;
    }
    .app_store_div {
      display: flex;
      align-items: center;
      background: black;
      width: 150px;
      height: 50px;
      border: 1px solid white;
      border-radius: 5px;
      padding: 5px;
      cursor: pointer;
      justify-content: center;
    }
    .download_text {
      font-size: 9px;
      margin: 0;
      color: white;
      margin-top: 13px;
      margin-left: 10px;
    }
    .app_store_text {
      font-size: 13px;
      color: white;
      margin-left: 10px;
    }
    .play_store_img {
      width: 20px;
    }
    .play_store_div {
      display: flex;
      align-items: center;
      background: black;
      width: 150px;
      height: 50px;
      border: 1px solid white;
      border-radius: 5px;
      padding: 5px;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;
    }
    .comming_soon_text {
      text-align: center;
      font-style: italic;
      font-family: "PlayfairDisplay-Black";
      src: url(./../assets/fonts/PlayfairDisplay-Italic.ttf) format("truetype");
      font-size: 20px;
      letter-spacing: -1px;
    }
    .bottom_download_div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 4%;
      right: 12%;
    }
    .apple_img {
      width: 20px;
    }
  
    .man2_img_div {
      position: absolute;
      right: 0;
      top: 0;
    }
  
    .man1_img_div {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
    }
    @media (max-width: 700px) {
      .man1_img_div {
        display: none !important;
      }
      .man2_img_div {
        display: none !important;
      }
      .man2_img_div_responsive {
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        position: absolute;
        right: 0;
        top: 0;
      }
      .man1_img_div_responsive {
        display: flex;
        align-items: flex-end;
        height: 100vh;
        justify-content: flex-start;
        position: absolute;
        bottom: 0;
      }
      .bottom_download_div {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }

    
  }
  

  .bg_main{
    .mobile_main{
      display: none;
    }
    @media (max-width: 700px) {
    
      .mobile_main{
        display: block;
      }
    
      .bg_landing{
        display: none;
      }
    
    }
  }

  .toggle-btn {
    background-color: rgba(0, 0, 0, 0);
    border-width: 0px;
    position: absolute;
    top: 30px;
    left: 30px;
    width:100;
    @media only screen and (max-width: 600px) {
      top: 15px;
      left: 15px;
    }
  }
  .toggle-btn-c {
    position: absolute;
    flex-direction: column;
    display: flex;
    top: 100px;
    left: 35px;
    @media only screen and (max-width: 600px) {
      top: 65px;
    left: 25px;
    }
    &.exceptional{
      @media only screen and (max-width: 600px) {
        position: relative;
      }
    }
    z-index: 1000000;
    .tgbtn {
      background-color: rgba(0, 0, 0, 0);
      border: none;
      color: white;
      font-size: 18px;
      margin-top: 7px;
      text-align: left;
    }
  }
  