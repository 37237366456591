.mobile-main {
    .bg_landing1 {
      background-image: url(./../assets/bg.png);
      background-size: contain;
      background-position: center;
      width: 100%;
      min-height: 100vh;
      background-color: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      padding-bottom: 40px;
      overflow-x: hidden !important;
    }
    
    .logo {
      width: 100%;
      // height: 200px;
      flex-direction: column;
      display: flex;
      align-items: center;
      height: auto;
      position: relative;
      z-index: 1000;
    }
    .text-lgc {
      background: -webkit-linear-gradient(#b3b3b3 49%, #ffffff 26.08%, #b3b3b3 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .lgcenterbtn {
      position: absolute;
      top: 60%;
      align-items: center;
      margin: auto;
      padding: 30;
      height: 50px;
      background-color: transparent;
      width: 200px;
      border-radius: 10px;
      border: 1px solid #fff;
    }
    .form-container {
      border: 1px solid white;
      background-origin: border-box;
      background-clip: content-box, border-box;
      border-radius: 10px;
      width: 500px;
      padding: 20px;
      border-radius: 10px;
  
      background-origin: border-box;
      background-clip: content-box, border-box;
      .submit-btn {
        background: -webkit-linear-gradient(
          #b3b3b3 0%,
          #ffffff 52.08%,
          #b3b3b3 100%
        );
        width: 250px;
        height: 45px;
        border-radius: 10px;
        border: none;
        margin: auto;
        text-align: center;
        display: block;
        margin-top: 30px;
      }
      .input-box {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: 400px;
        align-self: center;
        justify-content: center;
        margin: auto;
        margin-top: 20px;
        .input {
          border-width: 0;
          border-bottom: 1px solid #fff;
          background-color: transparent;
          width: 100%;
          color: white;
          outline: none;
        }
      }
    }
  
    .man1_img {
      // width: 550px;
      // height: '60%';
    }
    .man2_img {
      //   height: 30%;
    }
    // .man2_img_div {
    //   // width: 53%;
  
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    // }
    .man2_img_div_animate {
      width: 53%;
      position: absolute;
      right: 0;
      top: 0;
    }
    .man1_img_div_responsive_animate {
      width: 68%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
    }
    .logo_div {
      justify-content: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      margin-top: -124px;  }
    .man1_img_animate {
      // width: 57rem;
      // height: 480px;
      overflow: hidden !important;
      width: 100%;
    }
    .man2_img_animate {
      // width: 45rem;
      overflow: hidden !important;
      // height: 619px;
      width: 100%;
    }
    .wow,
    .animate__fadeInBottomLeft,
    .animate__fadeInTopRight {
      overflow: hidden !important;
    }
  
    :root {
      overflow: hidden !important;
    }
    .blur_div {
      width: 100%;
    }
    .blur_img_div {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      height: 100vh;
      position: absolute;
      width: 100%;
      z-index: 1000;
    }
    .blur_img {
      width: 35rem;
      width: 35rem;
      // margin-left: -20px;
      // margin-top: 100px;
    }
    .app_store_div {
      display: flex;
      align-items: center;
      background: black;
      width: 150px;
      height: 50px;
      border: 1px solid white;
      border-radius: 5px;
      padding: 5px;
      justify-content: center;
    }
    .download_text {
      font-size: 9px;
      margin: 0;
      color: white;
      margin-top: 13px;
      margin-left: 10px;
    }
    .app_store_text {
      font-size: 13px;
      color: white;
      margin-left: 10px;
    }
    .play_store_img {
      width: 20px;
    }
    .play_store_div {
      display: flex;
      align-items: center;
      background: black;
      width: 150px;
      height: 50px;
      border: 1px solid white;
      border-radius: 5px;
      padding: 5px;
      justify-content: center;
      margin-left: 10px;
    }
    .comming_soon_text {
      text-align: center;
      font-style: italic;
      font-family: "PlayfairDisplay-Black";
      src: url(./../assets/fonts/PlayfairDisplay-Italic.ttf) format("truetype");
      font-size: 20px;
      letter-spacing: -1px;
    }
    .bottom_download_div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 15%;
      right: 10%;
    }
    .apple_img {
      width: 20px;
    }
  }
  
  .bg_landing1 {
    position: relative;
  
    background-image: url(./../assets/mainBg.png);
    background-size: contain;
    background-position: center;
    width: 100%;
    min-height: 100vh;
    background-color: #000;
    display: flex;
    justify-content: space-between;
    //   align-items: center;
    //   flex-direction: column;
    padding-bottom: 40px;
    //   overflow-x: hidden !important;
  
    .logo {
      width: 100%;
      // height: 200px;
      flex-direction: column;
      display: flex;
      align-items: center;
    }
    .text-lgc {
      background: -webkit-linear-gradient(#b3b3b3 49%, #ffffff 26.08%, #b3b3b3 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .lgcenterbtn {
      position: absolute;
      top: 60%;
      align-items: center;
      margin: auto;
      padding: 30;
      height: 50px;
      background-color: transparent;
      width: 200px;
      border-radius: 10px;
      border: 1px solid #fff;
    }
    .form-container {
      border: 1px solid white;
      background-origin: border-box;
      background-clip: content-box, border-box;
      border-radius: 10px;
      width: 500px;
      padding: 20px;
      border-radius: 10px;
  
      background-origin: border-box;
      background-clip: content-box, border-box;
      .submit-btn {
        background: -webkit-linear-gradient(
          #b3b3b3 0%,
          #ffffff 52.08%,
          #b3b3b3 100%
        );
        width: 250px;
        height: 45px;
        border-radius: 10px;
        border: none;
        margin: auto;
        text-align: center;
        display: block;
        margin-top: 30px;
      }
      .input-box {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: 400px;
        align-self: center;
        justify-content: center;
        margin: auto;
        margin-top: 20px;
        .input {
          border-width: 0;
          border-bottom: 1px solid #fff;
          background-color: transparent;
          width: 100%;
          color: white;
          outline: none;
        }
      }
    }
    .man1_img_div_responsive {
      // display: flex;
      // justify-content: flex-start;
      // align-items: flex-end;
      // position: absolute;
      // bottom: 0;
      display: none;
    }
  
    .man2_img_div_responsive {
      display: none;
      // display: flex;
      // justify-content: flex-end;
      // align-items: flex-end;
      // position: absolute;
      // right: 0;
      // top: 0;
    }
    .man2_img_div_animate {
      width: 53%;
      position: absolute;
      right: 0;
      top: 0;
    }
    .man1_img_div_animate {
      width: 68%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      position: absolute;
      bottom: 0;
    }
    .logo_div {
      justify-content: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      margin-top: -124px;
    }
    .man1_img_animate {
      // width: 57rem;
      // height: 480px;
      overflow: hidden !important;
      width: 100%;
    }
    .man2_img_animate {
      // width: 45rem;
      overflow: hidden !important;
      // height: 619px;
      width: 100%;
    }
    .wow,
    .animate__fadeInBottomLeft,
    .animate__fadeInTopRight {
      overflow: hidden !important;
    }
  
    :root {
      overflow: hidden !important;
    }
    .blur_div {
      width: 100%;
    }
    .blur_img_div {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      height: 100vh;
      position: absolute;
      width: 100%;
      z-index: 1000;
    }
    .blur_img {
      width: 35rem;
      margin-left: -14px;
      margin-top: 25px;
    }
    .app_store_div {
      display: flex;
      align-items: center;
      background: black;
      width: 150px;
      height: 50px;
      border: 1px solid white;
      border-radius: 5px;
      padding: 5px;
      cursor: pointer;
      justify-content: center;
    }
    .download_text {
      font-size: 9px;
      margin: 0;
      color: white;
      margin-top: 13px;
      margin-left: 10px;
    }
    .app_store_text {
      font-size: 13px;
      color: white;
      margin-left: 10px;
    }
    .play_store_img {
      width: 20px;
    }
    .play_store_div {
      display: flex;
      align-items: center;
      background: black;
      width: 150px;
      height: 50px;
      border: 1px solid white;
      border-radius: 5px;
      padding: 5px;
      justify-content: center;
      margin-top: 10px;
      cursor: pointer;
    }
    .comming_soon_text {
      text-align: center;
      font-style: italic;
      font-family: "PlayfairDisplay-Black";
      src: url(./../assets/fonts/PlayfairDisplay-Italic.ttf) format("truetype");
      font-size: 20px;
      letter-spacing: -1px;
    }
    .bottom_download_div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 4%;
      right: 12%;
    }
    .apple_img {
      width: 20px;
    }
  
    .man2_img_div {
      // width: 53%;
  
      position: absolute;
      right: 0;
      top: 0;
    }
  
    .man1_img_div {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      position: absolute;
      bottom: 0;
      // width: 68%;
    }
  
    .bg_landing_responsive {
      display: block !important;
    }
    @media (max-width: 700px) {
      .bg_landing_responsive {
        display: none !important;
      }
      .mobile-main {
        .bg_landing1 {
          display: block;
        }
      }
      .man1_img_div {
        display: none !important;
      }
      .man2_img_div {
        display: none !important;
      }
      .man2_img_div_responsive {
        overflow: hidden;
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        top: 15px;
  
        justify-content: center;
      }
      .man1_img_div_responsive {
        display: flex;
        align-items: center;
        height: 100vh;
        justify-content: flex-start;
        position: absolute;
        bottom: 0;
      }
      .logo {
        // display: flex!important;
        // justify-self: center!important;
        // align-items: center!important;
        // width: 100%!important;
        // height: 100%!important;
      }
      
      .bottom_download_div {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
    .lgcenterbtnmobile {
      // position: absolute;
      // top: 45%;
      align-items: center;
      margin: auto;
      padding: 30;
      height: 50px;
      margin-top: 40px;
      background-color: transparent;
      width: 100px;
      border-radius: 10px;
      border: 1px solid #fff;
      z-index: 1000;
    }
    .join-btn{
        font-size: 10px;
    }
  }

  .menuIcon{
    width:50px;
    @media only screen and (max-width: 600px) {
      width:30px;
  
     }
  }


  
  
  